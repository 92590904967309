import React from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getChallengeQuestions,
  submitChallengeQuestionSubmission,
  getChallengeQuestionsLeaderboard,
} from '../utils/mmc-api/api';
import { useSelector, useDispatch } from 'react-redux';
import { updateDetails } from './../store/user';

import DocumentIcon from '../assets/icons/pdf2.svg';
import WordDocumentIcon from '../assets/icons/word-icon.png';
import YoutubeLogo from '../assets/images/youtube_logo.png';
import CloseIcon from '../assets/icons/close.svg';

const HEADERS = [
  {
    label: 'Question number',
    value: 'id',
  },
  {
    label: 'Rating',
    value: 'rating',
  },

  {
    label: 'Question',
    value: 'question_url',
  },
  {
    label: 'Video solution',
    value: 'video_solution',
  },
  {
    label: 'Submission',
    value: 'submission_time',
  },
  { label: 'Fastest', value: 'fastest' },
];

const LEADERBOARD_HEADERS = [
  { label: 'Position', value: 'index' },
  { label: 'User', value: 'username' },
  { label: '% correct', value: 'percentage' },
  { label: 'No. of top fives', value: 'topFives' },
  { label: 'Total score', value: 'totalScore' },
];

function Star({ size = '1rem', filled = true, color = 'black' }) {
  if (filled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        style={{
          width: size,
          height: size,
          color,
        }}
      >
        <path
          fillRule="evenodd"
          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
          clipRule="evenodd"
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        style={{
          width: size,
          height: size,
          color,
        }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
        />
      </svg>
    );
  }
}

function FileUpload({ onFileInput }) {
  const [file, setFile] = React.useState();
  const inputRef = React.useRef(null);

  const handleFileSelection = (e) => {
    const files = e.currentTarget.files;
    if (files) {
      setFile(files[0]);
      onFileInput(files[0]);
    }
  };

  return (
    <>
      <input
        type="file"
        ref={inputRef}
        onChange={(e) => handleFileSelection(e)}
        style={{ display: 'none' }}
      />

      <div
        className="challenge-file-input-container"
        onClick={() => inputRef.current.click()}
        role="button"
      >
        <button className="button">Select file</button>
        <input type="text" readOnly value={file?.name || 'Select a file'} />
      </div>
    </>
  );
}

export default function ChallengeQuestions() {
  const { data, isLoading } = useQuery(['challenge-questions'], getChallengeQuestions);
  const { data: leaderboardData, isLoading: leaderboardIsLoading } = useQuery(
    ['challenge-questions-leaderboard'],
    getChallengeQuestionsLeaderboard,
  );
  const [showingModal, setShowingModal] = React.useState(false);
  const [submissionIndex, setSubmissionIndex] = React.useState(null);
  const [submissionFile, setSubmissionFile] = React.useState();
  const user = useSelector((state) => state.user.data);
  const [userFullname, setUserFullName] = React.useState(null);
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const {
    mutate: submit,
    isLoading: submissionIsLoading,
    isError,
  } = useMutation({
    mutationFn: (form) => submitChallengeQuestionSubmission(form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['challenge-questions'] });
      dispatch(updateDetails({ fullname: userFullname }));
      closeModal();
    },
  });

  if (isLoading) return <p>Loading...</p>;

  const renderRow = (rowData, index, headers = HEADERS) => {
    return (
      <tr>{headers.map((header) => renderCell(header.value, rowData[header.value], index))}</tr>
    );
  };

  const renderCell = (header, value = '', index) => {
    if (header === 'index') {
      return <td className="text-centered">{index + 1}</td>;
    }
    if (header === 'fastest') {
      if (value.username) {
        return (
          <td className="text-centered">
            <p className="mb-0">
              {value.username}: {new Date(value.time).toLocaleDateString()}
            </p>
          </td>
        );
      }
      return <td></td>;
    }
    if (header === 'submission_time')
      return (
        <td className="text-centered">
          {value && <p className="mb-0">Submission time: {new Date(value).toLocaleDateString()}</p>}
          {!value && data[index].open && (
            <button
              className="button"
              onClick={() => {
                if (user) {
                  setSubmissionIndex(index);
                  setShowingModal(true);
                } else {
                  alert('You must be logged in to submit a solution');
                }
              }}
            >
              Submit
            </button>
          )}
          {!value && !data[index].open && (
            <p className="mb-0">Entries for this question is now closed</p>
          )}
        </td>
      );
    if (header === 'rating')
      return (
        <td className="text-centered">
          <div className="star-container">
            {[...Array(value)].map((_) => (
              <Star color="gold" />
            ))}
            {[...Array(5 - value)].map((_) => (
              <Star filled={false} />
            ))}
          </div>
        </td>
      );
    if (typeof value === 'string' && value?.includes('.pdf')) {
      return (
        <td className="text-centered">
          <img
            src={DocumentIcon}
            className="category-option-image doc-icon"
            alt="Category option"
            onClick={() => window.open(value, '_blank')}
          />
        </td>
      );
    }
    if (typeof value === 'string' && value?.includes('.docx')) {
      return (
        <td className="text-centered">
          <img
            src={WordDocumentIcon}
            className="category-option-image doc-icon"
            alt="Category option"
            onClick={() => window.open(value, '_blank')}
          />
        </td>
      );
    }
    if (typeof value === 'string' && value?.includes('youtube.com')) {
      return (
        <td className="text-centered">
          <img
            src={YoutubeLogo}
            onClick={() => window.open(value, '_blank')}
            className="category-option-image"
            alt="Category option"
          />
        </td>
      );
    }
    return <td className="text-centered">{value ?? 'Coming soon'}</td>;
  };

  const closeModal = () => {
    setShowingModal(false);
    setSubmissionIndex(null);
  };

  const handleSubmission = async () => {
    const form = new FormData();

    const request_data = {
      id: data[submissionIndex].id,
    };

    if (user.fullname === null) {
      if (!userFullname) {
        alert('You must provide your full name');
        return;
      }
      request_data.fullname = userFullname;
    }

    form.append('files.submission', submissionFile, submissionFile.name);
    form.append('data', JSON.stringify(request_data));

    submit(form);
  };

  return (
    <>
      <div className="challenge-questions-button-row mb-1">
        <a href="#questions">
          <button className="button">Questions</button>
        </a>
        <a href="#leaderboards">
          <button className="button">Leaderboards</button>
        </a>
        <a href="#terms-and-conditions">
          <button className="button">Terms and conditions</button>
        </a>
      </div>
      <h3 id="questions">Questions</h3>
      <table className="table topic-table" id="questions">
        <thead>
          <tr
            style={{
              textAlign: 'center',
              backgroundColor: '#fd99c5',
              border: '1px solid black',
            }}
          >
            {HEADERS.map((header) => (
              <th className="p-1" style={{ textAlign: 'center', border: '1px solid black' }}>
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length ? (
            data.map((row, i) => renderRow(row, i))
          ) : (
            <tr>
              <td colSpan={HEADERS.length}>No questions found</td>
            </tr>
          )}
        </tbody>
      </table>
      {leaderboardData && (
        <>
          <h3 id="leaderboards">Leaderboards</h3>
          <table className="table topic-table">
            <thead>
              <tr
                style={{
                  textAlign: 'center',
                  backgroundColor: '#fd99c5',
                  border: '1px solid black',
                }}
              >
                {LEADERBOARD_HEADERS.map((header) => (
                  <th className="p-1" style={{ textAlign: 'center', border: '1px solid black' }}>
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {leaderboardData?.length ? (
                leaderboardData.map((row, i) => renderRow(row, i, LEADERBOARD_HEADERS))
              ) : (
                <tr>
                  <td className="text-centered" colSpan={LEADERBOARD_HEADERS.length}>
                    The leaderboard is empty, could you be first?
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
      {showingModal && (
        <div className="modalWrapper challenge-modalWrapper">
          <div className="wideModal scrollable">
            <img
              className="modal-close-button"
              src={CloseIcon}
              alt="Close button for modal"
              onClick={closeModal}
            />
            <div className="text-center pb-1">
              <h2>Submit solution</h2>
              <p>
                Please ensure you submit the correct file. You are only allowed one submission per
                question.
              </p>
            </div>
            <p className="mb-0.75">Question number: {submissionIndex + 1}</p>
            <div className="flex mb-0.75">
              <span>Rating: </span>
              <div className="star-container">
                {[...Array(data[submissionIndex].rating)].map((_) => (
                  <Star color="gold" />
                ))}
                {[...Array(5 - data[submissionIndex].rating)].map((_) => (
                  <Star filled={false} />
                ))}
              </div>
            </div>
            {user.fullname === null && (
              <div className="mb-0.75">
                <p className="mb-0">Fullname (your name is required to display on leaderboards)</p>
                <input
                  type="text"
                  className="mb-0"
                  placeholder="Please enter your full name"
                  value={userFullname}
                  onChange={(e) => setUserFullName(e.target.value)}
                />
              </div>
            )}
            <div className="mb-0.75">
              <p className="mb-0">Solution</p>
              <FileUpload onFileInput={(e) => setSubmissionFile(e)} />
            </div>
            <div className="modalFooter">
              <button onClick={closeModal}>Close</button>
              <button className="button w-button" onClick={handleSubmission}>
                Submit solution
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
