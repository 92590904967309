import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, Link } from 'gatsby';

import { Layout, SEO } from '../components';
import {
    AboutSection,
    FAQsSection,
    TestimonialsSection,
    TopicSection,
    InteractiveLearningSection,
    MotivationSection,
} from '../sections';
import { filterSection } from '../utils/utils';
import { ContactForm } from '../components/ContactForm/ContactForm.view';
import { BookingForm } from '../components/BookingForm/BookingForm.view';
import VideoPlayer from '../components/VideoPlayer';
import { GamesSection } from '../sections/Games';
import ChallengeQuestions from '../sections/ChallengeQuestions';

const Page = ({
    data: {
        datoCmsPage,
        datoCmsPage: {
            callToAction,
            customContent,
            description,
            description2,
            section,
            seo,
            seoKeywords,
            slug,
            subtitle,
            title,
            schemaMarkup,
            video,
            videos,
            youtubeVideo,
        },
        allDatoCmsDocumentTopic,
        allDatoCmsDocument,
    },
}) => {
    React.useEffect(() => {
        if (window.location.pathname === '/forum')
            window.location.href = 'https://forum.mymathscloud.com';
    }, []);

    const allVideos = [
        ...(video?.video?.streamingUrl ? [video?.video?.streamingUrl] : []),
        ...(youtubeVideo ? youtubeVideo.split(',') : []),
        ...(videos?.length ? videos.map((vid) => vid.video.streamingUrl) : []),
    ];

    const renderCustomContent = (item) => {
        if (item.name === 'about-author') {
            const data = { ...customContent[0], callToAction: callToAction };
            return <AboutSection data={data} key={item.name} />;
        }
        return (
            <>
                <h3 id={item.name}>{item.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: item.description2 }} />
            </>
        )
    };

    const renderSection = (section) => {
        if (section.name === 'faqs') {
            return (
                <FAQsSection
                    data={filterSection('section', 'faqs', datoCmsPage).section[0]}
                    key={section.name}
                />
            );
        }
    };

    const renderLink = (link) => {
        return link.externalLink ? (
            <a
                className="button button-space-top w-button"
                href={link.externalLink}
                key={link.externalLink}
                style={{ marginRight: 5 }}
            >
                {link.label}
            </a>
        ) : (
            <Link
                to={`/${link.slug}`}
                className="button button-space-top w-button"
                key={link.slug}
                style={{ marginRight: 5 }}
            >
                {link.label}
            </Link>
        );
    };

    const renderPage = () => {
        switch (slug) {
            case 'contact':
                return <ContactForm description={description} />;
            case 'booking':
                return <BookingForm description={description} courses={section[0].section[0].courses} />;
            case 'testimonials':
                return <TestimonialsSection data={customContent} />;
            case 'topics':
                return (
                    <TopicSection
                        data={{ topics: allDatoCmsDocumentTopic.edges, documents: allDatoCmsDocument.edges }}
                    />
                );
            case 'interactive-learning':
                return <InteractiveLearningSection />;
            case 'games':
                return <GamesSection />;
            case 'motivation':
                return <MotivationSection videos={customContent ?? []} />;
            case 'competition':
                return <ChallengeQuestions />
            default:
                return null;
        }
    };

    const renderSeo = () => (
        <SEO
            description={seo && seo.description}
            image={seo && seo.image}
            title={seo && seo.title}
            seoKeywords={seoKeywords}
            schema={schemaMarkup && JSON.parse(schemaMarkup)}
            noIndex={['privacy-policy'].includes(slug) ? true : undefined}
        />
    );

    return (
        <Layout>
            {renderSeo()}
            <article className="content-section">
                <div className="container">
                    {title && <h1>{title}</h1>}
                    <div className="mb-1.5">{subtitle && <h2>{subtitle}</h2>}</div>
                    {slug !== 'topics' ? (
                        <div className="container-flex flex-col">
                            {allVideos.length
                                ? allVideos.map((src) => <VideoPlayer className="video-player" isSmall src={src} />)
                                : null}
                        </div>
                    ) : null}
                    {description && <ReactMarkdown className="paragraph" children={description} />}
                    {!description && description2 && (
                        <div dangerouslySetInnerHTML={{ __html: description2 }} />
                    )}
                    {slug === 'topics' ? (
                        <div className="container-flex flex-col">
                            {allVideos.length
                                ? allVideos.map((src) => <VideoPlayer className="video-player" isSmall src={src} />)
                                : null}
                        </div>
                    ) : null}
                    {renderPage()}
                    {customContent.length > 0 && customContent.map(renderCustomContent)}
                    {section.length > 0 && section.map(renderSection)}
                    {callToAction.length > 0 && callToAction.map(renderLink)}
                </div>
            </article>
        </Layout>
    );
};

export default Page;

const query = graphql`
  query PageQuery($slug: String!) {
    datoCmsPage(slug: { eq: $slug }) {
      title
      subtitle
      description
      description2
      slug
      seo {
        description
        title
        image {
          url
        }
      }
      seoKeywords {
        keyword
      }
      video {
        video {
          streamingUrl
        }
      }
      videos {
        video {
          streamingUrl
        }
      }
      youtubeVideo
      customContent {
        shortDescription
        description
        description2
        name
        title
        images {
          alt
          url
          video {
            streamingUrl
          }
        }
        youtubeVideo
      }
      callToAction {
        label
        slug
        externalLink
      }
      section {
        name
        section {
          ... on DatoCmsSection {
            id
            collapsibleBlock {
              description
              title
            }
            description
            image {
              alt
              url
            }
            subtitle
            title
          }
          ... on DatoCmsModule {
            id
            comingSoon
            description
            image {
              alt
              url
            }
            slug
            position
            title
            subCategory {
              name
              slug
            }
          }
          ... on DatoCmsTeachingCourse {
            id
            courses {
              name
            }
          }
        }
      }
      schemaMarkup
    }
    allDatoCmsDocumentTopic {
      edges {
        node {
          name
          id
        }
      }
    }
    allDatoCmsDocument(filter: { module: { slug: { eq: "topics" } } }) {
      edges {
        node {
          id
          description
          freeDocument
          freeSample
          hasPassword
          isFeatured
          isSubscription
          keywords
          externalUrl
          name
          displayName
          price
          originalId
          fileUrl
          documentTopic {
            name
            id
          }
          documentCategory {
            slug
          }
          module {
            slug
          }
        }
      }
    }
  }
`;
export { query };
