import { gql } from '@apollo/client';

export const SUBMIT_CONTACT_FORM = gql`
  mutation SUBMIT_CONTACT_FORM(
    $fName: String!
    $lName: String!
    $email: String!
    $course: String!
    $message: String!
  ) {
    submitContactForm(
      fName: $fName
      lName: $lName
      email: $email
      course: $course
      message: $message
    ) {
      success
      message
    }
  }
`;

export const SUBMIT_BOOKING_FORM = gql`
  mutation SUBMIT_BOOKING_FORM(
    $fName: String!
    $lName: String!
    $email: String!
    $courses: [String!]!
    $requirements: String!
  ) {
    submitBookingForm(
      fName: $fName
      lName: $lName
      email: $email
      courses: $courses
      requirements: $requirements
    ) {
      success
      message
    }
  }
`;
