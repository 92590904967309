import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

export const GAMES_QUERY = graphql`
  query GamesQuery {
    allDatoCmsGamePage {
      edges {
        node {
          title
          slug
          thumbnail {
            url
          }
        }
      }
    }
  }
`;

export function GamesSection() {
  /**
   * @type {{
   *    allDatoCmsGamePage: {
   *      edges: {
   *        node: {
   *          title: String
   *          slug: String
   *          thumbnail?: {
   *            url: String
   *          }
   *        }
   *      }[]
   *    }
   * }}
   */
  const data = useStaticQuery(GAMES_QUERY);

  return (
    <div className="interactive-learning">
      <h3>Exercises</h3>
      <div className="brainteasers-container">
        {data.allDatoCmsGamePage.edges.map(({ node: { title, slug, thumbnail } }) => (
          <Link to={`/games${slug}`} key={slug} className="brainteaser-grid-item">
            <div className="image-container">{thumbnail && <img src={thumbnail.url} />}</div>
            <div className="title-container">
              <p>{title}</p>
            </div>
          </Link>
        ))}
      </div>
      <div className="grid"></div>
    </div>
  );
}
