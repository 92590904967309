import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { SUBMIT_BOOKING_FORM } from '../../graphql/mutations/forms';

const initialFormState = {
  fName: '',
  lName: '',
  courses: [],
  requirements: '',
};

export const BookingForm = ({ courses }) => {
  const constructCoursesObj = courses.reduce((acc, curr, idx, arr) => {
    const course = {
      [curr.name]: {
        checked: false,
      },
    };
    acc = {
      ...acc,
      ...course,
    };
    return acc;
  }, {});

  const [email, setEmail] = useState();
  const [selectedCourses, setSelectedCourses] = useState(constructCoursesObj);
  const [formFields, setFormFields] = useState(initialFormState);
  const [formState, setFormState] = useState('');
  const [submitBookingForm] = useMutation(SUBMIT_BOOKING_FORM);

  const onChange = (e, field) => {
    setFormFields({
      ...formFields,
      [field]: e.target.value,
    });
  };

  const onCourseChange = (e, field) => {
    const newCourse = {
      [field]: {
        checked: !selectedCourses[field].checked,
      },
    };
    const newCourses = {
      ...selectedCourses,
      ...newCourse,
    };
    setSelectedCourses(newCourses);
  };

  const onEmailChange = e => setEmail(e.target.value);

  const handleSubmit = async e => {
    e.preventDefault();
    setFormState('loading');

    try {
      const coursesArray = Object.entries(selectedCourses)
        .map(course => {
          if (course[1].checked) {
            return course[0];
          }

          return false;
        })
        .filter(val => !!val);
      const result = await submitBookingForm({
        variables: {
          ...formFields,
          courses: coursesArray,
          email,
        },
      });
      if (result.data.submitBookingForm.success) {
        setFormState('success');
      }
    } catch (err) {

      setFormState('error');
    }
  };

  const renderCourse = course => (
    <div className="checkbox">
      <label className="w-form-label">
        <input name="course" onChange={e => onCourseChange(e, course)} type="checkbox" />
        <span>{course}</span>
      </label>
    </div>
  );

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <label htmlFor="fName">
        <span>First name*</span>
      </label>
      <input
        name="fName"
        className="w-input"
        onChange={e => onChange(e, 'fName')}
        placeholder="Your first name"
        required
      />

      <label htmlFor="lName">
        <span>Last name*</span>
      </label>
      <input
        name="lName"
        className="w-input"
        onChange={e => onChange(e, 'lName')}
        placeholder="Your last name"
        required
      />

      <label htmlFor="email">
        <span>Email*</span>
      </label>
      <input
        name="email"
        className="w-input"
        onChange={onEmailChange}
        placeholder="Your email address"
        required
      />

      <label htmlFor="requirements">
        <span>Requirements*</span>
      </label>
      <textarea
        name="requirements"
        className="w-input"
        onChange={e => onChange(e, 'requirements')}
        placeholder="Please give details of which exam board(s) your child is studying for and which timings (e.g. Christmas holidays, Easter revision) you are interested in booking for."
        required
      />

      <label htmlFor="course">
        <span>Courses*</span>
      </label>

      {Object.keys(selectedCourses).map(renderCourse)}

      <button disabled={formState === 'loading'} type="submit" className="button-blue w-button">
        {formState === 'loading' ? 'Working on it...' : 'Submit form'}
      </button>
    </form>
  );

  const renderThanksMessage = () => (
    <p>
      Thanks for submitting your message {formFields.fName}! I will get back to you as soon as I
      can.
    </p>
  );

  return formState === 'success' ? renderThanksMessage() : renderForm();
};
